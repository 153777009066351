import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const HolidaySetter = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [holidays, setHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [holidayType, setHolidayType] = useState('weekoff');

  const fetchHolidays = async () => {
    try {
      const response = await axiosInstance.get('/admin/holidays');
      setHolidays(response.data);
      // Initialize filteredHolidays with all holidays
      setFilteredHolidays(response.data);
    } catch (error) {
      toast.error('An error occurred while fetching holidays.');
    }
  };

  const filterHolidaysByYear = (year) => {
    const filtered = holidays.filter((holiday) => {
      const holidayYear = new Date(holiday.date).getFullYear();
      return holidayYear === year;
    });
    setFilteredHolidays(filtered);
  };

  useEffect(() => {
    fetchHolidays();
  }, []);

  useEffect(() => {
    // Filter holidays when selected year changes
    filterHolidaysByYear(selectedYear);
  }, [selectedYear, holidays]); // Include holidays dependency

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post(
        '/admin/holidays',
        {
          date,
          description,
          holidayType,
        },
        {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` },
        }
      );

      toast.success(response.data); // Display the response from the backend
      await fetchHolidays(); // Fetch the updated list of holidays
      resetForm(); // Reset the form fields
    } catch (error) {
      if (error.response) {
        if (error.response.data.result) {
          toast.error(error.response.data.result);
          navigate('/login');
        } else {
          toast.error(error.response.data);
        }
      } else if (error.request) {
        toast.error('No response from the server. Please try again later.');
        navigate('/login');
      } else {
        toast.error('Error occurred while submitting the form. Please try again later.');
        navigate('/login');
      }
    }
  };
  const resetForm = () => {
    setDate('');
    setDescription('');
  };

  return (
    <div className="container py-3">
      <form onSubmit={handleSubmit}>

        <div className="row mb-3">
          <div className="form-group col-md-3">
            <label htmlFor="holidayType">Holiday Type</label>
            <select
              id="holidayType"
              className="form-select"
              value={holidayType}
              onChange={(e) => setHolidayType(e.target.value)}
            >
              <option value="weekoff">Weekoff</option>
              <option value="holiday">Holiday</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <label htmlFor="fromDate">Date</label>
            <input
              id="fromDate"
              className="form-control"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="form-group col-md-5">
            <label htmlFor="reason">Holiday Description</label>
            <textarea
              id="reason"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
        </div>

        <button className="btn btn-primary mt-3" type="submit">
          Apply Holiday
        </button>
      </form>
      <div className='row mt-5 align-items-center'>
        <label htmlFor="year" className="col-auto fs-5 fw-bold"> {/* col-auto to size based on content */}
          <i className="bi bi-calendar-fill me-2"></i> {/* Bootstrap icon */}
          Select Year
        </label>
        <div className="col-auto">
          <select
            id="year"
            className="form-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {[...Array(3)].map((_, i) => {
              const year = new Date().getFullYear() - 1 + i;
              return <option key={year} value={year}>{year}</option>;
            })}
          </select>
        </div>
      </div>


      <div style={{ marginTop: '2%' }}>

        <table className="table table-bordered table-striped">
          <thead>
            <tr className="text-center">
              <th className="col-md-3">Date</th>
              <th className="col-md-6">Description</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {filteredHolidays.length === 0 ? (
              <tr>
                <td colSpan="2">No holidays available for the selected year.</td>
              </tr>
            ) : (
              filteredHolidays
              .filter(holiday => holidayType === 'weekoff' ? holiday.holidayType === 'WO' : holiday.holidayType === 'H')
              .map((holiday) => (
                <tr key={holiday._id}>
                  <td>{holiday.date}</td>
                  <td>{holiday.description}</td>
                </tr>
              ))
            )}
          </tbody>

        </table>

      </div>
    </div>
  );
};

export default HolidaySetter;