import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';

const Editinout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [employee, setEmployee] = useState({
    emp_id: '',
    date: '',
    clockIn: '',
    lunchStart: '',
    lunchComplete: '',
    clockOut: '',
    Time: '',
    status: '',
  });
  const [timeData, setTimeData] = useState({ Time: '', status: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const calculateDuration = (employee) => {
    const { clockIn, lunchStart, lunchComplete, clockOut } = employee || {};
    if (clockIn && clockOut) {
      const startDate = new Date(`2000-01-01 ${clockIn}`);
      const lunchStartDate = new Date(`2000-01-01 ${lunchStart}`);
      const lunchCompleteDate = new Date(`2000-01-01 ${lunchComplete}`);
      const endDate = new Date(`2000-01-01 ${clockOut}`);

      const lunchDuration = lunchCompleteDate - lunchStartDate;
      let totalDuration = endDate - startDate - lunchDuration;

      if (totalDuration < 0) {
        const midnight = new Date(`2000-01-02 00:00:00`);
        totalDuration = midnight - startDate + endDate - midnight - lunchDuration;
      }

      const sevenHoursFiftyMinutes = (7 * 60 * 60 * 1000) + (60 * 60 * 1000);
      totalDuration += sevenHoursFiftyMinutes - (8 * 60 * 60 * 1000);

      const hours = Math.floor(totalDuration / 3600000);
      const minutes = Math.floor((totalDuration % 3600000) / 60000);

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      const timeString = `${formattedHours}:${formattedMinutes}`;
      const status = hours > 7 || (hours === 7 && minutes >= 50) ? 'P' : hours >= 4 ? 'H/P' : 'A';

      return { timeString, status };
    }

    return { timeString: '', status: '' };
  };

  const updateEmployeeState = (employee) => {
    const { timeString, status } = calculateDuration(employee);
    setTimeData({ Time: timeString, status });
  };

  useEffect(() => {
    if (location.state && location.state.rowData) {
      const { _id } = location.state.rowData;
      const employeeName = location.state.employeeName;

      const fetchEmployeeData = async () => {
        try {
          const response = await axiosInstance.get(`/employee/entry/${_id}`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            },
          });
          const employeeData = response.data;
          // Set employeeName from state and other data from API response
          setEmployee(prevState => ({
            ...prevState,
            employeeName,
            emp_id: employeeData.emp_id,
            date: employeeData.date,
            clockIn: parseTimeString(employeeData.clockIn),
            lunchStart: parseTimeString(employeeData.lunchStart),
            lunchComplete: parseTimeString(employeeData.lunchComplete),
            clockOut: parseTimeString(employeeData.clockOut),
            status: employeeData.status
          }));
        } catch (error) {
          toast.error('Failed to fetch employee data');
        }
      };

      // Parse date strings in the format "HH:mm AM/PM" to convert them to 24-hour format
      const parseTimeString = (timeString) => {
        if (!timeString) return ''; // Check if timeString is available
        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':');
        let hours24 = parseInt(hours, 10);
        if (period === 'PM' && hours24 !== 12) {
          hours24 += 12;
        } else if (period === 'AM' && hours24 === 12) {
          hours24 = 0;
        }
        return `${hours24.toString().padStart(2, '0')}:${minutes}`;
      };

      fetchEmployeeData(); // Call the fetchEmployeeData function
    } else {
      toast.error('No data available for editing');
      navigate('/Admin/dashboard');
    }
  }, [location.state, navigate]);


  useEffect(() => {
    updateEmployeeState(employee);
  }, [employee.clockIn, employee.lunchStart, employee.lunchComplete, employee.clockOut]);

  const handleUpdateEmployee = async () => {
    const { _id } = location.state.rowData;
    try {
      const updatedEmployee = {
        ...employee,
        status: employee.status
      };

      await axiosInstance.post(`/employee/entry/${_id}`, updatedEmployee, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
      });

      toast.success('Employee entry Updated successfully.');
      navigate('/Admin/attendance');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      } else {
        toast.error('Failed to update entry.');
        navigate('/Admin/attendance');
      }
    }
  };

  const handleCancel = () => {
    navigate('/Admin/attendance');
  };

  return (
    <div className="container py-3">
      <div className="row">
        <div className='col-md-10'></div>
        <div className='col-md-2 mb-3'>
          <button className="btn btn-primary float-end" onClick={handleCancel}>Back</button>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-header">
          <h2>Entry For Employee</h2>
        </div>
        <div className="card-body">
          <p>Employee ID: {employee.emp_id}</p>
          <p>Employee Name: {employee.employeeName}</p>
          <p>Date: {employee.date}</p>
          <form>
            <div className="row mt-3">
              <div className="col-md-3 mb-3">
                <label className="form-label">Clock In</label>
                <input
                  type="time"
                  className="form-control"
                  name="clockIn"
                  value={employee.clockIn}
                  onChange={handleInputChange}
                  step="3600"
                />
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label">Lunch start</label>
                <input
                  type="time"
                  className="form-control"
                  name="lunchStart"
                  value={employee.lunchStart}
                  onChange={handleInputChange}
                  step="3600"
                />
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label">Lunch complete</label>
                <input
                  type="time"
                  className="form-control"
                  name="lunchComplete"
                  value={employee.lunchComplete}
                  onChange={handleInputChange}
                  step="3600"
                />
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label">Clock Out</label>
                <input
                  type="time"
                  className="form-control"
                  name="clockOut"
                  value={employee.clockOut}
                  onChange={handleInputChange}
                  step="3600"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <label className="form-label">Time</label>
                <input
                  type="text"
                  className="form-control"
                  name="Time"
                  value={timeData.Time}
                  readOnly
                />
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label">Status</label>
                <select
                  className="form-select"
                  name="status"
                  value={employee.status}
                  onChange={(e) => setEmployee({ ...employee, status: e.target.value })}
                >
                  <option value="P">Present</option>
                  <option value="H/P">Half-Day Present</option>
                  <option value="H/P/L">Half-Day Leave half Day Present</option>
                  <option value="A">Absent</option>
                  <option value="L">Leave</option>
                  <option value="H/L">Half-Day Leave</option>
                  <option value="WO">Week-off</option>
                  <option value="H">Holiday</option>
                </select>
              </div>

            </div>
            <div className="d-flex gap-2">
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={handleUpdateEmployee}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger btn-md"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Editinout;