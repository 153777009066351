import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import axiosInstance from './axiosInstance';
import Select from 'react-select';

const Entry = () => {
  const [entries, setEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const emp_id = Cookies.get('emp_id');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEntries();
  }, [selectedMonth]);

  const fetchEntries = async () => {
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = selectedMonth + 1;
  
      const response = await axiosInstance.get(`/inout-list/${emp_id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
      });
      const apiData = response.data;
      const generatedEntries = generateEntriesForMonth(year, month);
  
      const filteredApiData = apiData.filter((apiEntry) => {
        const entryMonth = new Date(apiEntry.date).getMonth() + 1;
        return entryMonth === selectedMonth + 1;
      });
  
      const combinedEntries = [...generatedEntries];
  
      filteredApiData.forEach((apiEntry) => {
        const matchingEntryIndex = generatedEntries.findIndex((entry) => entry.date === apiEntry.date);
        if (matchingEntryIndex !== -1) {
          combinedEntries[matchingEntryIndex] = {
            ...apiEntry,
            status: apiEntry.status // Add status property to the entry
          };
        } else {
          combinedEntries.push(apiEntry);
        }
      });
  
      setEntries(combinedEntries);
    } catch (error) {
      toast.error('Something went wrong. Please login');
      navigate('/login');
    }
  };
  

  const generateEntriesForMonth = (year, month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
  
    const daysInMonth = new Date(year, month, 0).getDate();
    const generatedEntries = [];
  
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const entry = {
        date: formatDate(date),
        clockIn: '',
        lunchStart: '',
        lunchComplete: '',
        clockOut: '',
        status: 'A' // Set status as "A" for generated entries
      };
  
      if (date.getDay() === 0) {
        entry.status = 'S';
      } else if (year === currentYear && month === currentMonth && i > currentDay) {
        entry.status = ''; // Empty status for future dates
      }
  
      generatedEntries.push(entry);
    }
  
    return generatedEntries;
  };  

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const calculateTotalTime = (clockIn, clockOut, lunchStart, lunchComplete) => {
    if (clockIn && clockOut) {
      const startTime = new Date(`2000/01/01 ${clockIn}`);
      const endTime = new Date(`2000/01/01 ${clockOut}`);
      const diffMilliseconds = endTime - startTime;

      let lunchDurationMilliseconds = 0;
      if (lunchStart && lunchComplete) {
        const lunchStartTime = new Date(`2000/01/01 ${lunchStart}`);
        const lunchEndTime = new Date(`2000/01/01 ${lunchComplete}`);
        lunchDurationMilliseconds = lunchEndTime - lunchStartTime;
      }

      const totalTimeMilliseconds = diffMilliseconds - lunchDurationMilliseconds;
      const totalHours = Math.floor(totalTimeMilliseconds / 1000 / 60 / 60);
      const totalMinutes = Math.floor((totalTimeMilliseconds / 1000 / 60) % 60);
      const formattedHours = String(totalHours).padStart(2, '0');
      const formattedMinutes = String(totalMinutes).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    }
    return '';
  };

  const monthOptions = Array.from({ length: new Date().getMonth() + 1 }, (_, index) => {
    const monthNumber = index;
    const monthName = new Date(0, monthNumber).toLocaleString('en-US', { month: 'long' });
    return { value: monthNumber, label: monthName };
  });

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  return (
<div className="row">
<div className="col-2">
        <div className="col-auto">
          <label htmlFor="monthSelect" className="form-label me-2">
            Select Month:
          </label>
          <Select
            id="monthSelect"
            options={monthOptions}
            value={monthOptions.find((option) => option.value === selectedMonth)}
            onChange={handleMonthChange}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </div>
  </div>  
      <div className="col-9">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="sticky-top bg-primary">
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Clock In</th>
              <th>Lunch Start</th>
              <th>Lunch Complete</th>
              <th>Clock Out</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry.date}</td>
                <td>{entry.clockIn}</td>
                <td>{entry.lunchStart}</td>
                <td>{entry.lunchComplete}</td>
                <td>{entry.clockOut}</td>
                <td>{calculateTotalTime(entry.clockIn, entry.clockOut, entry.lunchStart, entry.lunchComplete)}</td>
                <td>{entry.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default Entry;