import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axiosInstance from './axiosInstance';
import Select from 'react-select';
import * as XLSX from "xlsx";
import { BsFileExcel } from 'react-icons/bs';

const Attendance = () => {
  const [entries, setEntries] = useState([]);
  const [data, setData] = useState([]); // State for the fetched data
  const [employeeOptions, setEmployeeOptions] = useState([]); // State for the options in the Select component
  const [selectedEmployee, setSelectedEmployee] = useState(null); // State for the selected employee  
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const navigate = useNavigate();
  
      useEffect(() => {
      fetchData();
    }, [selectedMonth]);    
  
  const fetchEntries = async () => {
    if (!selectedEmployee) {
      toast.error('Please select an employee first');
      return; // Exit the function if no employee is selected
    }

    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = selectedMonth + 1;

      const response = await axiosInstance.get(`/inout-list/${selectedEmployee}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
      });
      const apiData = response.data;
      const generatedEntries = generateEntriesForMonth(year, month);

      const filteredApiData = apiData.filter((apiEntry) => {
        const entryMonth = new Date(apiEntry.date).getMonth() + 1;
        return entryMonth === selectedMonth + 1;
      });

      const combinedEntries = [...generatedEntries];

      filteredApiData.forEach((apiEntry) => {
        const matchingEntryIndex = generatedEntries.findIndex((entry) => entry.date === apiEntry.date);
        if (matchingEntryIndex !== -1) {
          combinedEntries[matchingEntryIndex] = {
            ...apiEntry,
            status: apiEntry.status // Add status property to the entry
          };
        } else {
          combinedEntries.push(apiEntry);
        }
      });

      setEntries(combinedEntries);
    } catch (error) {
      toast.error('Something went wrong. Please login');
      navigate('/login');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/Allemp', {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
      });
      const jsonArray = response.data;
      // Map the array to include both 'name' and 'value' properties
      const employeeOptions = jsonArray.map((item) => ({
        label: item.name,
        value: item.emp_id, // Assuming '_id' as the unique identifier for employees
      }));

      setData(jsonArray);
      setEmployeeOptions(employeeOptions); // Assuming you have a state variable for employee options
    } catch (error) {
      toast.error("Something went wrong.");
      navigate('/login');
    }
  };



  const generateEntriesForMonth = (year, month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    const daysInMonth = new Date(year, month, 0).getDate();
    const generatedEntries = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const entry = {
        date: formatDate(date),
        clockIn: '',
        lunchStart: '',
        lunchComplete: '',
        clockOut: '',
        status: 'A' // Set status as "A" for generated entries
      };

      if (date <= currentDate) {
        if (date.getDay() === 0) {
          entry.status = 'S';
        }
        generatedEntries.push(entry);
      }
    }

    return generatedEntries;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const calculateTotalTime = (clockIn, clockOut, lunchStart, lunchComplete) => {
    if (clockIn && clockOut) {
      const startTime = new Date(`2000/01/01 ${clockIn}`);
      const endTime = new Date(`2000/01/01 ${clockOut}`);
      const diffMilliseconds = endTime - startTime;

      let lunchDurationMilliseconds = 0;
      if (lunchStart && lunchComplete) {
        const lunchStartTime = new Date(`2000/01/01 ${lunchStart}`);
        const lunchEndTime = new Date(`2000/01/01 ${lunchComplete}`);
        lunchDurationMilliseconds = lunchEndTime - lunchStartTime;
      }

      const totalTimeMilliseconds = diffMilliseconds - lunchDurationMilliseconds;
      const totalHours = Math.floor(totalTimeMilliseconds / 1000 / 60 / 60);
      const totalMinutes = Math.floor((totalTimeMilliseconds / 1000 / 60) % 60);
      const formattedHours = String(totalHours).padStart(2, '0');
      const formattedMinutes = String(totalMinutes).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    }
    return '';
  };

  const monthOptions = Array.from({ length: new Date().getMonth() + 1 }, (_, index) => {
    const monthNumber = index;
    const monthName = new Date(0, monthNumber).toLocaleString('en-US', { month: 'long' });
    return { value: monthNumber, label: monthName };
  });

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };
  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
  };
  const handleButtonClick = () => {
    fetchEntries();
  };

  const handleExport = () => {
    if (entries.length > 0) { // Check if entries array is not empty
      const employeeName = employeeOptions.find((option) => option.value === selectedEmployee)?.label;
      const dataToExport = entries.map((entry) => ({
        Date: entry.date,
        ClockIn: entry.clockIn,
        LunchStart: entry.lunchStart,
        LunchComplete: entry.lunchComplete,
        ClockOut: entry.clockOut,

        Time: calculateTotalTime(entry.clockIn, entry.clockOut, entry.lunchStart, entry.lunchComplete),
        PresentDay: entry.status === 'P' ? '1.0' : entry.status === 'H/P/L' ? '1.0' : entry.status === 'H/P' ? '0.5' : entry.status === 'H/L' ? '0.5' : entry.status === 'L' ? '1.0' : '0.0',
        Status: entry.status
      }));
      // Convert the data to a worksheet
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Attendance Data");
      // Save the workbook to a file
      XLSX.writeFile(wb, `${employeeName}.xlsx`);
    } else {
      toast.error("No data for export ");
    }
  };


  const handleEdit = (rowData) => {
    if (rowData && selectedEmployee) {
      const selectedEmployeeName = employeeOptions.find((option) => option.value === selectedEmployee)?.label;
      navigate('/Admin/entry', { state: { rowData, employeeName: selectedEmployeeName } });
    } else {
      toast.error('No data available for editing');
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'P':
      case 'H/P':
      case 'H/P/L':
        return 'success';
      case 'A':
        return 'danger';
      case 'L':
      case 'H/L':
        return 'warning';
      case 'S':
        return 'info';
      default:
        return 'info';
    }
  };

  const currentDate = new Date().toISOString().slice(0, 10);


  return (
    <div className="row m-2">

      <div className="row">
        <div className="col-5">
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="employeeSelect" className="form-label me-2">
                Select Employee
              </label>
              <Select
                id="employeeSelect"
                options={employeeOptions}
                value={employeeOptions.find((option) => option.value === selectedEmployee)}
                onChange={handleEmployeeChange}
                classNamePrefix="react-select"
                className="react-select-container"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999 // Adjust the z-index value as needed
                  })
                }}
              />
            </div>
            <div className="col">
              <label htmlFor="monthSelect" className="form-label me-2">
                Select Month
              </label>
              <Select
                id="monthSelect"
                options={monthOptions}
                value={monthOptions.find((option) => option.value === selectedMonth)}
                onChange={handleMonthChange}
                classNamePrefix="react-select"
                className="react-select-container"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999 // Adjust the z-index value as needed
                  })
                }}
              />
            </div>
            <div className="col mt-4 d-flex justify-content-center">
              <button onClick={handleButtonClick} className="btn btn-primary btn-md">
                Filter
              </button>
            </div>
          </div>
        </div>
        <div className="col-7 d-flex justify-content-end mb-3 mt-3">
          <button onClick={handleExport} className="btn btn-primary btn-md rounded-10px" data-bs-toggle="tooltip" data-bs-placement="top" title="Export to Excel">
            <BsFileExcel /> Export
          </button>
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="sticky-top bg-primary">
              <tr>
                <th>Date</th>
                <th>Clock In</th>
                <th>Lunch Start</th>
                <th>Lunch Complete</th>
                <th>Clock Out</th>
                <th>Time</th>
                <th>Present Day</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.date}</td>
                  <td>{entry.clockIn}</td>
                  <td>{entry.lunchStart}</td>
                  <td>{entry.lunchComplete}</td>
                  <td>{entry.clockOut}</td>
                  <td>{calculateTotalTime(entry.clockIn, entry.clockOut, entry.lunchStart, entry.lunchComplete)}</td>
                  <td>{entry.status === 'P' ? '1.0' : entry.status === 'H/P/L' ? '1.0' : entry.status === 'H/P' ? '0.5' : entry.status === 'H/L' ? '0.5' : entry.status === 'L' ? '1.0' : '0.0'}</td>
                  <td>
                    <span className={`badge bg-${getStatusClass(entry.status)}`}>
                      {entry.status}
                    </span>
                  </td>
                  <td>
                    <button onClick={() => handleEdit(entry)} className="btn btn-primary">Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
